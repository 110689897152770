*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  // font-size: 62.5%;
  font-family: 'Kanit', sans-serif;
}

.btn {
  min-width: 100px;
  display: inline-block;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  text-transform: capitalize;
  outline: none;
  // &:focus,
  // &:active {
  //   border: none;
  //   outline: none;
  //   transform: translateY(0);
  //   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  // }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  }
  &:active {
    transform: translateY(0px);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  }

  &--light {
    background: #eee;
  }

  &--secondary {
    background: rgb(255 215 69);
  }

  &--primary {
    background: #6487bb;
    color: #fff;

    &:hover {
      background: @color-primary;
      color: #fff;
    }
  }
}
