.rbc-off-range-bg {
  background: none;
}

.rbc-month-view {
  border: none;
}

.rbc-header {
  border-bottom: none;
}

.rbc-month-header {
  flex: 0.6 0;
}

.rbc-month-row + .rbc-month-row {
  border-top: none;
}

.rbc-header + .rbc-header,
.rbc-day-bg + .rbc-day-bg {
  border-left: none;
}
.rbc-date-cell {
  text-align: center;
}
.rbc-today {
  background: none;
}
.rbc-date-cell.rbc-now.rbc-current {
  font-weight: inherit;
}
.rbc-date-cell > a {
  font-size: 14px;
}

.date_current {
  display: block;
  background: #0046a0;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-left: 45%;
  padding-top: 2px;
  padding-right: 1px;
  color: #ffff;
  opacity: 1;
}
.rbc-day-bg.rbc-selected-cell {
  background: #0046a0;
  height: 37px;
}

@media (max-width: 1204px) {
  .rbc-month-header {
    flex: 0.9 0;
  }
  .date_current {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-left: 30%;
    padding-top: 8px;
    padding-right: 2px;
  }
}

@media (max-width: 768px) {
  .rbc-month-header {
    flex: 0.7 0;
  }
  .date_in_calendar {
    font-size: 22px;
  }
  .date_current {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-left: 32%;
    padding-top: 2px;
    padding-right: 2px;
  }
}
@media (max-width: 450px) {
  .date_current {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    margin-left: 20%;
  }
}
@media (max-width: 320px) {
  .date_current {
    width: 20px;
    height: 20px;
    margin-left: 21%;
  }
}
