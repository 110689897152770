div.calendar_custom {
  height: 500px;
}
.mt_5 {
  margin-top: 5px;
}
.container {
  padding: 10px 20px 10px;
  overflow: initial;
}
.fix_height_100 {
  height: 100%;
}
.fix_height {
  height: 100vh;
}
.fix_height_site {
  height: 92vh;
}
.toolbar_container {
  width: 100%;
  padding-bottom: 20px;
}
.btn_back,
.btn_next {
  width: 100%;
  border: none;
  background: none;
  font-size: 28px;
  font-weight: 100;
}
.btn_back {
  text-align: left;
}
.btn_next {
  text-align: right;
}
.label_date {
  justify-content: center;
  display: flex;
  height: 45px;
  align-items: center;
}
.btn_next,
.on_click_back {
  color: #0046a0;
}
.disabled_click {
  color: #d6dbe7;
}
.btn_in_calendar {
  background: none;
  border: none;
}
.calendar_lists_events {
  justify-content: center;
}
.events_list {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-left: 2%;
}
.box_list_events {
  justify-content: center;
}

.box_lists_events {
  display: block;
  width: 100%;
  padding: 17px;
  background-color: #ffffff;
  box-shadow: 0 0px 45px 0 #dee0e6;
  position: relative;
  z-index: 9999;
  bottom: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.position_relative {
  position: relative;
}
.position_absolute {
  position: absolute;
}
.header {
  height: 100px;
  background: #0046a0;
  padding: 40px 15px 15px;
}
.title_page {
  font-size: 18px;
  text-align: center;
  color: #ffff;
}
.header_box_left,
.header_box_right {
  width: 10%;
}
.header_box_center {
  width: 80%;
  text-align: center;
  padding-top: 1%;
}
span.icon_go_to_back {
  color: #ffff;
}
.btn_reset,
.btn_filter {
  border: none;
  background: none;
  font-size: 14px;
  letter-spacing: -0.5px;
  font-family: 'Prompt';
  line-height: 30px;
}
.btn_reset {
  color: #ffff;
}

.box_title_events {
  width: 80%;
}
.box_btn_filter {
  width: 20%;
  text-align: right;
  padding-right: 30px;
}
.box_half_circle {
  width: 90%;
  position: absolute;
  display: flex;
  justify-content: center;
  top: -20px;
}

.btn_filter {
  color: #0046a0;
  text-decoration: underline;
}

.title_events {
  font-family: 'Prompt';
  font-size: 22px;
  color: #0046a0;
  letter-spacing: -0.5px;
  line-height: 30px;
  font-weight: 500;
}
.half_circle {
  width: 50px;
  height: 30px;
  background-color: #ffff;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border: none;
  border-bottom: 0;
  font-weight: bold;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.list_data {
  width: 100%;
}
.list_data_check {
  width: 100%;
  pointer-events: none !important;
}
.title_name {
  color: #445064;
  width: 100%;
}
.box_name {
  width: 30%;
  border-bottom: 2px solid #00000029;
}
.box_date {
  width: 28%;
  border-bottom: 2px solid #00000029;
}
.box_car {
  width: 40%;
  border-bottom: 2px solid #00000029;
}
.border_left {
  height: 34px;
  border-left: 4px solid;
  position: relative;
  border-color: #0046a0;
  margin-top: 5px;
  border-radius: 20px;
}
.car_name {
  display: flex;
}
.circle_car {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 3px;
  margin-top: 3px;
}
.car_registration {
  margin-left: 10px;
}
.date_reserve {
  margin-bottom: 0px;
  color: #445064;
}
.reserve_allday {
  margin-bottom: 0px;
}
.car_registration,
.reserve_allday,
.dis_name {
  font-size: 10px;
  color: #445064;
}

.box_name {
  padding-left: 10px;
}

.box_list_data_events::-webkit-scrollbar,
.box_list_cars::-webkit-scrollbar,
.select_hour::-webkit-scrollbar,
.select_minute::-webkit-scrollbar {
  display: none;
}
.box_list_data_events {
  height: 200px;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.border_opacity {
  opacity: 0.5;
}
.box_btn_bottom {
  text-align: center;
  margin-top: 15px;
  padding: 10px 0px;
}
.btn_bottom,
.btn_submit {
  background: #0046a0;
  border-radius: 20px;
  color: #ffff;
  height: 35px;
  border: none;
}
.btn_bottom {
  width: 100%;
}
.btn_submit {
  width: 90%;
}
.cards {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 166px;
  height: 255px;
  background: #ffff;
  border-radius: 25px;
  border: 1px solid #0046a0;
  opacity: 0.6;
  margin-right: 10px;
}
.box_list_cars {
  display: flex;
  padding: 10px 0px 20px 20px;
  overflow-y: scroll;
}
.box_list_car {
  display: flex;
  padding: 10px 0px 20px 20px;
  justify-content: center;
}
.box_img_car {
  padding: 20px 15px 10px;
}
.img_car {
  width: 135px;
  height: 90px;
  border-radius: 15px;
  background: #e3e3e3;
}
.box_detail_list_car {
  padding: 0px 15px 20px;
}
.title_car_name {
  font-size: 14px;
  color: #445064;
}
.card_car_registration {
  color: #445064;
  margin-bottom: 0px;
  line-height: 13px;
  font-size: 12px;
}
.box_title_car_name {
  width: 100%;
  display: flex;
}
.dot_card {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-left: 3px;
  margin-top: 3px;
}
.box_rateing {
  margin-right: 0;
  padding: 3px 0px;
}
ul.rate_car {
  color: #0046a0;
  font-size: 12px;
}
.des_location_car {
  width: 85%;
  display: flex;
}
.box_radio {
  width: 15%;
  text-align: right;
  margin-top: 8px;
}
.icon_location_haupcar {
  margin-top: 4px;
  margin-right: 2px;
  width: 24px;
  height: 24px;
}
.des_location_p {
  color: #445064;
  font-size: 10px;
}
label.radio_select_car {
  margin-top: 5px;
  text-align: right;
  margin-left: 5px;
  border-color: #0046a0;
}
label.radio_select_car > span > span {
  border-color: #0046a0 !important;
}
label.radio_select_car > span > span::after {
  background-color: #0046a0;
}
.allday_box {
  border-bottom: 1px solid #8e8f9c;
  border-top: 1px solid #8e8f9c;
}
.daysfoweek,
.allday_box {
  width: 100%;
  padding: 15px;
  display: flex;
}
.box_label_switch,
.box_switch {
  width: 50%;
}
.box_switch {
  text-align: right;
}
.lable_switch {
  font-size: 14px;
  color: #445064;
}
.switch_haup {
  background-color: #8e8f9c;
}
.checked_allday,
.check_daysfoweek,
.check_dayOfweek {
  color: #0046a0;
}
.check_dayOfweek {
  font-weight: bold;
}
.disabled {
  opacity: 0.5;
}
.form_data {
  overflow-x: auto;
  width: 100%;
  height: 100%;
}
.form_data::-webkit-scrollbar {
  display: none;
}
.box_btn_submit {
  text-align: center;
  position: relative;
  bottom: 0px;
  width: 100%;
  z-index: 999;
  height: 60px;
  background: #ffff;
}
.box_select_time {
  border-top: 1px solid #8e8f9c;
}
.box_icon_clock {
  width: 100%;
  display: flex;
  padding: 20px 10px 10px;
}
.box_btn_select_time {
  display: flex;
  width: 100%;
  justify-content: center;
}
.btn_set_time {
  width: 70%;
  border: 1px solid;
  border-color: #0046a0;
  height: 37px;
  text-align: center;
  color: #0046a0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.box_line_column {
  width: 100%;
  display: flex;
  justify-content: center;
}
.line_column {
  width: 0%;
  border-left: 1px solid #0046a0;
  height: 15px;
}
.select_time_picker {
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}
.time_active {
  background: #0046a0;
  color: #ffff;
}
.box_title_filter {
  padding: 15px 15px 0px;
  display: flex;
}
.title_filter {
  color: #445064;
  font-size: 25px;
  width: 70%;
  font-weight: 500;
  font-family: 'Prompt';
}
.box_checkbox {
  width: 30%;
  justify-content: right;
  padding-top: 5px;
  display: flex;
  padding-top: 5px;
}
.link_to {
  color: #0046a0;
  text-decoration: underline;
}
.check_box {
  width: 34px;
  height: 19px;
}
.box_rateing_filter {
  display: flex;
  width: 100%;
  justify-content: center;
}
ul.rateing_filter {
  color: #0046a0;
  font-size: 80px;
}
.box_availability_checked {
  width: 100%;
  padding: 18px 15px 18px 30px;
  display: flex;
}
.check_box_availability {
  width: 30%;
  display: block;
  text-align: right;
}
.title_availability {
  font-size: 25px;
  width: 70%;
  font-family: Prompt;
}
div.dialog_mui_custom {
  height: 50%;
  top: unset;
}
.header_dialog {
  padding-top: 10px;
}
.close_btn {
  border: none;
  background: none;
  color: #445064;
  font-size: 14px;
}
.text_default {
  color: #445064;
}
.box_title_time_picker {
  width: 100%;
  display: block;
  text-align: center;
  padding: 15px 0px;
}
.title_time_picker {
  font-size: 16px;
}
.date_time_picker {
  font-size: 12px;
}
.box_select_time_picker {
  width: 100%;
  height: 200px;
  display: flex;
  overflow: hidden;
}
.select_hour,
.select_minute {
  overflow-y: scroll;
  width: 50%;
  height: 200px;
}
.container_timepicker {
  padding: 10px 20px 10px;
  overflow: hidden;
}
.number_hours,
.number_minute {
  width: 100%;
  border: none;
  background: none;
  font-size: 16px;
  color: #445064;
  padding: 5px 0px;
}
.active_hour {
  color: #0046a0;
  background: #d6dbe7;
}
.dialog_box_submit {
  text-align: center;

  margin-top: -20px;
}
.box_title_start_enf_time {
  display: flex;
  justify-content: center;
  width: 100%;
}
.title_start_time,
.title_end_time {
  font-size: 14px;
  color: #0046a0;
}
.title_start_time {
  padding-left: 35px;
}
.box_title_time {
  text-align: center;
  width: 39%;
}
div.timepicker_ant {
  height: 36px;
  border-radius: 10px;
  border-color: #00469f;
  width: 70%;
  margin-left: 50px;
}
.icon_trash {
  padding: 8px 15px;
}

.show_box_time {
  display: block;
}

.none_box_time {
  display: none;
}

.select_car_card {
  opacity: 1;
}
.display_none {
  display: none;
}
.box_name.check_date_width {
  width: 32%;
}
.box_car.check_date_width {
  width: 36%;
}
.box_radio_event {
  padding-top: 10px;
}

.btn_edit,
.btn_confirm {
  float: left;
}
.btn_delete {
  width: 100%;
  text-align: center;
}
a.btn_bottom {
  padding: 8px;
}
.box_lists_events {
  height: 35%;
}
.box_lists_events_full {
  height: 76%;
}
.select_time_long {
  margin-left: 20px;
}
.btn_undo {
  float: right;
}
.btn_confirm,
.btn_undo,
.btn_edit {
  width: 45%;
}
.check_box {
  margin-top: 6px;
}
.all_rating {
  font-size: 18px;
  color: #0046a0;
}
.header_box_right {
  text-align: right;
  padding-right: 15px;
}
main.container_box {
  margin: 30px 35px 0px;
}
.box_list_data_events {
  height: 70%;
}
.box_list_data_events_full {
  height: 80%;
}

/* new test */
/* .select_time_picker {
  align-items: center;
  width: 100%;
} */
.testnaja {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}
.select_hour,
.select_minute {
  overflow: hidden;
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: column;
}
/* new test */
@media (max-width: 1204px) {
  div.calendar_custom {
    height: 650px;
    padding: 0px 40px;
  }
  .btn_in_calendar {
    font-size: 25px;
  }
  .date_in_calendar {
    font-size: 25px;
  }
  .btn_back,
  .btn_next {
    font-size: 50px;
  }
  .label_date {
    font-size: 25px;
    height: 80px;
  }
  .header {
    height: 150px;
  }
  .label.backoutdate_title_name__rY3QY {
    font-size: 25px;
  }
  .car_registration,
  .reserve_allday,
  .dis_name {
    font-size: 18px;
  }
  .box_date,
  .box_car {
    padding-left: 100px;
  }
  .title_events {
    font-size: 35px;
  }
  .btn_reset,
  .btn_filter {
    font-size: 24px;
  }
  .box_title_events {
    width: 76%;
  }
  .row_title_events {
    height: 50px;
  }
  .box_lists_events {
    padding: 30px 40px;
  }
  .title_name {
    font-size: 25px;
  }
  .date_reserve {
    font-size: 18px;
  }
  .btn_bottom {
    height: 60px;
    font-size: 20px;
  }
  .title_page,
  .go_to_back {
    font-size: 30px;
  }
  .go_to_back {
    margin-left: 20px;
  }
  .circle_car,
  .events_list {
    height: 15px;
    width: 15px;
    margin-top: 10px;
  }
  .border_left {
    height: 60px;
  }
  .title_start_time {
    font-size: 18px;
  }
  .header_box_left,
  .header_box_right {
    margin-top: 0px;
  }
  .box_btn_filter {
    width: 24%;
  }
  .box_btn_filter {
    padding-right: 26px;
  }
}
@media (max-width: 768px) {
  .header_box_center {
    padding-top: 2%;
  }
  .title_page {
    font-size: 25px;
  }
  .label_date {
    font-size: 22px;
    height: 60px;
  }
  .btn_back,
  .btn_next {
    font-size: 40px;
  }
  .btn_in_calendar {
    font-size: 20px;
  }
  .rbc-month-header {
    flex: 0.6 0;
  }
  .title_events {
    font-size: 30px;
  }
  .btn_filter {
    font-size: 20px;
    float: right;
  }
  div.calendar_custom {
    height: 500px;
  }
  .box_lists_events {
    height: 34%;
  }
  .date_in_calendar {
    font-size: 22px;
  }
  .circle_car,
  .events_list {
    height: 12px;
    width: 12px;
    margin-top: 6px;
  }
  .date_current {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-left: 30%;
    padding-top: 0px;
    padding-right: 0px;
  }
  .title_name {
    font-size: 20px;
  }
  .car_registration,
  .reserve_allday,
  .dis_name {
    font-size: 15px;
  }
  .border_left {
    height: 50px;
  }
  .box_btn_bottom {
    margin-top: 12px;
  }
  .btn_bottom {
    height: 40px;
    font-size: 18px;
  }
  .box_date,
  .box_car {
    padding-left: 90px;
  }
  .box_lists_events_full {
    height: 76%;
  }
  .calendar_custom {
    height: 490px;
  }
  .title_start_time,
  .title_end_time {
    font-size: 16px;
  }
  .btn_reset,
  .btn_filter,
  .go_to_back {
    font-size: 20px;
  }
  .box_date.check_date_width {
    width: 25%;
  }
}
@media (max-width: 540px) {
  .header_box_center {
    padding-top: 0;
  }
  .title_page {
    font-size: 18px;
  }
  .label_date {
    font-size: 18px;
    height: 30px;
  }
  .btn_back,
  .btn_next {
    font-size: 25px;
  }
  .btn_in_calendar,
  .date_in_calendar {
    font-size: 16px;
  }
  .title_events {
    font-size: 20px;
  }
  .btn_reset,
  .btn_filter,
  .go_to_back {
    font-size: 16px;
  }
  .title_name,
  .date_reserve {
    font-size: 16px;
  }
  .box_date,
  .box_car {
    padding-left: 40px;
  }
  .car_registration,
  .reserve_allday,
  .dis_name {
    font-size: 12px;
  }
  .border_left {
    height: 40px;
  }
  .header {
    height: 90px;
    padding: 25px 15px;
  }
  .box_lists_events {
    padding: 17px;
    /* height: 40%; */
  }
  .box_btn_bottom {
    margin-top: 10px;
    /* height: 60px; */
  }
  div.calendar_custom {
    height: 330px;
    padding: 0px 10px;
  }
  .circle_car,
  .events_list {
    height: 8px;
    width: 8px;
    margin-top: 4px;
  }
  .btn_bottom {
    font-size: 16px;
    height: 35px;
  }
  .box_list_data_events {
    height: 65%;
  }
}
@media (max-width: 450px) {
  .title_name,
  .date_reserve,
  .btn_reset,
  .btn_filter,
  .go_to_back {
    font-size: 13px;
  }
  .title_events {
    font-size: 18px;
  }
  .box_lists_events {
    height: 47%;
  }
  .box_list_data_events {
    height: 75%;
  }
  .box_list_data_events_full {
    height: 85%;
  }
  .box_date,
  .box_car {
    padding-left: 22px;
  }
  .box_lists_events_full {
    height: 76%;
  }
  .box_btn_bottom {
    margin-top: 0;
  }
  main.container_box {
    margin: 30px 10px 0px;
  }
  .box_date.check_date_width {
    width: 23%;
  }
  div.timepicker_ant {
    width: 74%;
    margin-left: 35px;
  }
  .title_start_time {
    padding-left: 0;
  }
  .title_end_time {
    padding-left: 0px;
  }
  .title_filter {
    font-size: 18px;
  }
  ul.rateing_filter {
    font-size: 60px;
  }
  .check_box {
    margin-top: 0;
  }
  .all_rating {
    font-size: 14px;
  }
  .title_availability {
    font-size: 16px;
  }
  .box_availability_checked {
    padding: 8px 10px 8px 30px;
  }
  .box_lists_events {
    bottom: -15px;
  }
}
@media (max-width: 450px) and (max-height: 667px) {
  .header {
    height: 70px;
    padding: 25px 10px;
  }
  .box_lists_events {
    height: 35%;
  }
  .box_lists_events_full {
    height: 76%;
    /* off  test */
    /* bottom: -73px; */
  }
  .box_list_data_events {
    height: 50%;
  }
  .box_list_data_events_full {
    height: 80%;
  }
  .box_btn_bottom {
    margin-top: 5px;
  }
  .btn_bottom {
    font-size: 14px;
    height: 30px;
  }
}
@media (max-width: 375px) {
  ul.rateing_filter {
    font-size: 54px;
  }
}

@media (max-width: 360px) {
  .box_lists_events {
    height: 40%;
  }
  .box_lists_events_full {
    height: 75%;
  }
  .box_list_data_events_full {
    height: 90%;
  }
  .row_title_events {
    height: 30px;
  }
  .title_name,
  .date_reserve,
  .btn_reset,
  .btn_filter,
  .go_to_back {
    font-size: 12px;
  }
  .header_box_left,
  .header_box_right {
    margin-top: 0px;
  }
  div.timepicker_ant {
    width: 71%;
  }
  .box_title_time {
    width: 36%;
  }
  ul.rateing_filter {
    font-size: 50px;
  }
  .title_filter {
    font-size: 16px;
  }
  .title_availability {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .box_name.check_date_width {
    width: 32%;
  }
  .title_name,
  .date_reserve,
  .btn_reset,
  .btn_filter,
  .go_to_back {
    font-size: 10px;
    margin-bottom: 6px;
  }
  .car_registration,
  .reserve_allday,
  .dis_name {
    font-size: 8px;
  }
  .label_date {
    font-size: 16px;
    height: 20px;
  }
  .btn_back,
  .btn_next {
    font-size: 18px;
  }
  .btn_in_calendar,
  .date_in_calendar {
    font-size: 12px;
  }
  .toolbar_container {
    padding-bottom: 10px;
  }
  main.container_box {
    margin: 20px 10px 0px;
  }
  div.calendar_custom {
    height: 300px;
  }
  .title_events {
    font-size: 14px;
  }
  .row_title_events {
    height: 30px;
  }
  .box_lists_events {
    height: 40%;
    padding: 8px;
  }
  .go_to_back {
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: 0px;
  }
  .header_box_left,
  .header_box_right {
    margin-top: 0px;
  }
  .box_date,
  .box_car {
    padding-left: 20px;
  }
  .btn_bottom {
    font-size: 12px;
    height: 26px;
  }
  .border_left {
    height: 32px;
  }
  .box_lists_events_full {
    height: 76%;
  }
  .box_name {
    width: 30%;
  }
  .box_car {
    width: 40%;
  }
  .box_car.check_date_width,
  .box_name.check_date_width {
    width: 34%;
  }
  .box_date.check_date_width,
  .box_car.check_date_width {
    padding-left: 10px;
  }
  .box_car.check_date_width {
    width: 34%;
  }
  .box_title_time {
    width: 39%;
  }
  .title_start_time,
  .title_end_time {
    font-size: 14px;
  }
  div.timepicker_ant {
    height: 30px;
  }
  ul.rateing_filter {
    font-size: 48px;
  }
  .all_rating {
    font-size: 11px;
    color: #0046a0;
  }
  .check_box {
    margin-top: -1px;
  }
}

