.calendar::-webkit-scrollbar {
  display: none;
}

.calendar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: hidden;
  width: 100%;
  flex-flow: unset !important;
}

.calendar .vehicle-card-col:first-child {
  padding-left: 16px !important;
}

.calendar .vehicle-card-col:last-child {
  padding-right: 16px !important;
}

p {
  margin-bottom: unset !important;
}

.ant-progress-success-bg {
  background-color: #064aa2 !important;
}

.ant-progress-bg {
  background-color: #b9cbe1 !important;
}

.fullWidthSpace .ant-space-item:nth-child(2) {
  width: inherit !important;
}

.white-bg {
  background-color: #ffffff;
}

.ant-progress-bg,
.ant-progress-success-bg {
  height: 12px !important ;
}

.ant-progress-text {
  color: #80a3cf !important;
  font-size: 0.8rem !important;
}

.section-header {
  font-size: 1.5rem;
  color: #607ca1;
}

.profile {
  font-size: xx-small;
}

.vehicle-card .ant-card-body {
  padding: 8px;
}

.box-btn-manage {
  text-align: right;
  padding-top: 10px;
}

button.btn,
a.btn {
  border-radius: 20px;
  padding: 6px 10px;
  width: 170px;
}

button.btn-primary,
a.btn-primary {
  color: #ffff;
  background: rgb(41, 69, 160);
}
