@import './_base.less';
@import './_variables.less';

@import './_utilities.less';
@import './_AddVehicle.less';

.logo {
  height: 40px;
  margin: 16px;
  text-align: center;
  align-items: center;
  /* background: rgba(255, 255, 255, 0.3); */
}

.site-layout .site-layout-background {
  background: #fff;
}
[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}

// .headerMenu {
//   background: #76c0f9;
// }

.headerMenu h1 {
  font-size: x-large;
  font-weight: normal;
}

.site-page-header {
  background: white;
}

.rightNav {
  text-align: right;
  color: white;
  line-height: 10px;
  margin: auto auto;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: unset;
  text-align: center;
}

#root {
  height: 100%;
}

/* Override Description Component */

.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: unset !important;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border-bottom: unset !important;
  border: unset !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: navy;
}

.switch_haup.ant-switch {
  background-color: #8e8f9c;
}
.switch_haup.ant-switch-checked {
  background-color: #17438a;
}
ul.ant-picker-time-panel-column {
  overflow-y: scroll;
}

.ant-picker-input > input {
  text-align: center;
  color: #0046a0;
}
.ant-picker-time-panel-column::-webkit-scrollbar {
  display: none;
}
.ant-picker-time-panel-column:not(:first-child) {
  border-left: none;
}

.ant-radio-checked .ant-radio-inner:after {
  background: #0046a0;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #0046a0 !important;
}
// div.ant-col {
//   width: 100%;
//   flex: none !important;
// }
// span.ant-radio-inner {
//   width: 25px;
//   height: 25px;
// }
// .ant-radio-inner::after {
//   top: 2px;
//   left: 2px;
//   width: 19px;
//   height: 19px;
// }
.swal2-container {
  z-index: 99999 !important;
}
.swal2-styled.swal2-cancel {
  background-color: #ffff;
  color: #0046a0;
  border: 1px solid #0046a0;
}
// @media (max-width: 768px) {
//   span.ant-radio-inner {
//     width: 20px;
//     height: 20px;
//   }
//   .ant-radio-inner::after {
//     top: 2px;
//     left: 2px;
//     width: 14px;
//     height: 14px;
//   }
// }
// @media (max-width: 450px) {
//   span.ant-radio-inner {
//     width: 16px;
//     height: 16px;
//   }
//   .ant-radio-inner::after {
//     top: 1px;
//     left: 1px;
//     width: 12px;
//     height: 12px;
//   }
// }
// @media (max-width: 320px) {
//   span.ant-radio-inner {
//     width: 14px;
//     height: 14px;
//   }
//   .ant-radio-inner::after {
//     top: 1px;
//     left: 1px;
//     width: 10px;
//     height: 10px;
//   }
// }

@layout-header-background: #76c0f9;