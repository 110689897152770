.pad-content {
  padding: 1rem;
}

h3 {
  color: #0046a0;
}
body {
  font-family: 'Prompt', sans-serif;
}
.ant-divider {
  border-top: 1px solid #445064b3;
}

.ant-modal-content {
  overflow: auto;
  border-radius: 2rem;
  padding-top: 1rem;
}

@primary-color: #0046a0;

@layout-header-background: #76c0f9;