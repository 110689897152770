.grid-form {
  display: grid;
  column-gap: 2rem;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 1200px) {
    // background: red;
    grid-template-columns: repeat(2, 1fr);
  }
}

.input-group {
  display: flex;
  flex-direction: column;
  &--left {
    grid-column-start: 1;
    grid-column-end: 3;

    @media screen and (max-width: 1200px) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
  &--right {
    grid-column-start: 3;
    grid-column-end: 5;
    @media screen and (max-width: 1200px) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

.color-picker {
  display: flex;
}
.color-picker input {
  display: none;
}

.color {
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  transition: all 0.3s ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  &:not(:last-of-type) {
    margin-right: 8px;
  }

  &:hover {
    transform: scale(1.2);
  }
}

@colors: {
  black: black;
  white: #fff;
  grey: grey;
  silver: silver;
  brown: brown;
  red: red;
  green: green;
  blue: blue;
  yellow: yellow;
  lightblue: lightblue;
};

each(@colors, {

	#@{key}:checked ~ .color--@{key} {
		transform: scale(1.2) translateY(-8px);
		box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);

		&::after {
			content: '@{key}';
			display: inline-block;
			width: min-content;
			height: 100%;
			position: absolute;
			bottom: 0;
			left: 50%;
			color: black;
      transform:
      translateX(-50%)
      translateY(25px);
      text-align: center;
      text-transform: capitalize;
		}
  }
	.color--@{key}{
		background: @value;
	}
});

// #basic_transmissionType {
//   width: 100%;
// }

// MODIFY to antd
.ant-radio-button-wrapper {
  padding: 0;
  text-align: center;
  width: 50%;
}
